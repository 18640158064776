import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserContext from './UserContext';
import Home from './HomePage';
import SignUp from './SignUp';
import GenerateEmails from './GenerateEmails';
import PurchaseEmails from './PurchaseEmails';
import Pricing from './Pricing';
import HowItWorks from './HowItWorks';
import Leads from './Leads';
import ArchiveLeads from './ArchiveLeads';
import Leads2 from './Leads2';
import Dashboard from './Dashboard';
import Billing from './Billing';
import Settings from './Settings';
import InsertChannels from './InsertChannels';
import ContactUs from './ContactUs';
import EmailsHistory from './EmailsHistory';
import ResetPassword from './ResetPassword';
import Blog from './Blog';
import BlogPost from './BlogPost';
import ThreePanels from './ThreePanels';

function safeParse(value) {
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error('Error parsing JSON from localStorage:', error);
    return null;
  }
}

function App() {
  const [user, setUser] = useState(null);

  // Load user from localStorage on mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = safeParse(storedUser);
      if (parsedUser) {
        setUser(parsedUser);
      } else {
        localStorage.removeItem('user');
      }
    }
  }, []);

  // Whenever `user` changes, update (or remove) it in localStorage
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<SignUp />} />
          <Route path="/generate" element={<GenerateEmails />} />
          <Route path="/purchase-emails" element={<PurchaseEmails />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/archive-leads" element={<ArchiveLeads />} />
          <Route path="/leads2" element={<Leads2 />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/insert-channels" element={<InsertChannels />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/emails-history" element={<EmailsHistory />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/three-panels" element={<ThreePanels />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;