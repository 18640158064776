// Emails.js

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import './Leads.css';
import Header from './Header';
import axios from 'axios';
import { marked } from 'marked';

function Leads() {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [expandedChannels, setExpandedChannels] = useState({});
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [isYoutuberDropdownOpen, setIsYoutuberDropdownOpen] = useState(false);
  const [selectedYoutuber, setSelectedYoutuber] = useState('');
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 960);
  const [isYoutuberPopupOpen, setIsYoutuberPopupOpen] = useState(false);
  const [isBrandPopupOpen, setIsBrandPopupOpen] = useState(false);
  const [neededCount, setNeededCount] = useState(null);
  const [error, setError] = useState(null);

  // Track the last request's _id
  const [lastRequestId, setLastRequestId] = useState(null);

  const contentEditableRef = useRef(null);

  // Group emails by channelName
  const groupedEmails = useMemo(() => {
    const groups = {};
    emails.forEach((email) => {
      const channelName = email.channelName || 'Unknown Channel';
      if (!groups[channelName]) {
        groups[channelName] = [];
      }
      groups[channelName].push(email);
    });
    return groups;
  }, [emails]);

  // On mount, fetch last request & active subscription
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) return;

    // 1) Get the latest request for this user
    fetchLastRequest(user._id);

    // 2) Also fetch subscription info (emailsPerYoutuber, youtubers, etc.)
    fetchActiveSubscription(user._id);
  }, []);

  // Once we have the last request's ID, immediately fetch the emails for that request
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !lastRequestId) return;
    fetchEmails(user._id, lastRequestId);
  }, [lastRequestId]);

  // Keep polling until we get all needed emails for that request
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !lastRequestId) return;

    // If we haven't reached neededCount yet, poll every 5s
    if (neededCount === null || emails.length < neededCount) {
      const intervalId = setInterval(() => {
        fetchEmails(user._id, lastRequestId);
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [neededCount, emails, lastRequestId]);

  // When emails change, expand only the first channel by default & select first email
  useEffect(() => {
    if (emails.length > 0 && Object.keys(expandedChannels).length === 0) {
      const initialExpandedChannels = {};
      Object.keys(groupedEmails).forEach((channelName, index) => {
        initialExpandedChannels[channelName] = index === 0;
      });
      setExpandedChannels(initialExpandedChannels);

      const firstChannelName = Object.keys(groupedEmails)[0];
      const firstEmail = groupedEmails[firstChannelName]?.[0];
      if (firstEmail) {
        setSelectedEmail(firstEmail);
      }
    }
  }, [emails, groupedEmails, expandedChannels]);

  // Cleanup / UI setup
  useEffect(() => {
    document.body.classList.add('emails-page');
    return () => document.body.classList.remove('emails-page');
  }, []);

  // Handle page size
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 960);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // If the user completes Gmail OAuth
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    if (status === 'success') {
      setAuthenticated(true);

      const pendingAction = localStorage.getItem('pendingAction');
      if (pendingAction === 'sendAllEmails') {
        localStorage.removeItem('pendingAction');
        sendAllEmails();
      } else if (pendingAction === 'sendEmail') {
        localStorage.removeItem('pendingAction');
        sendEmail();
      } else {
        alert('Email sent successfully!');
      }
    } else if (status === 'failed') {
      alert('Failed to send email.');
    }
    window.history.replaceState({}, document.title, '/leads');
  }, []);

  // --- FETCHING LOGIC ---

  // Fetch the last request for this user
  const fetchLastRequest = async (userId) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      const token = storedUser?.token;
      if (!token) {
        console.error('No authentication token found.');
        return;
      }

      const res = await axios.get(`/api/requests/last/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },  // << include token
      });

      if (res.data && res.data.request) {
        setLastRequestId(res.data.request._id);
      } else {
        console.warn('No last request found.');
      }
    } catch (err) {
      console.error('Error fetching last request:', err);
    }
  };

  // Fetch the active subscription & compute neededCount
  const fetchActiveSubscription = async (userId) => {
    try {
      const res = await axios.get(`/api/subscriptions/${userId}/active`);
      if (res.data) {
        const emailsPerYoutuber = res.data.emails || 10;
        const youtubers = res.data.youtubers || 3;
        const totalEmails = emailsPerYoutuber * youtubers;
        setNeededCount(totalEmails);
      } else {
        console.warn('No active subscription found; defaulting neededCount to 30.');
        setNeededCount(30);
      }
    } catch (err) {
      console.error('Error fetching active subscription:', err);
      setNeededCount(30);
    }
  };

  // Fetch emails from the server, filtered by lastRequestId
  const fetchEmails = async (userId, requestId) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      const token = storedUser?.token;
      if (!token) {
        console.error('No authentication token found.');
        setError('You must be logged in to fetch emails.');
        setEmails([]);
        setSelectedEmail(null);
        return;
      }

      // Pass requestId in query params so server can filter by last request
      const response = await axios.get(
        `/api/emails/${userId}?requestId=${requestId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data && Array.isArray(response.data.emails)) {
        // Filter out emails with status "sent"
        const filteredEmails = response.data.emails.filter(
          (email) => email.status !== 'sent'
        );

        // Convert email content to HTML
        const emailsWithHTMLContent = filteredEmails.map((email) => ({
          ...email,
          content: marked(email.content, { breaks: true }),
        }));

        setEmails(emailsWithHTMLContent);

        // Keep the current selection if it's still valid
        setSelectedEmail((currentSelectedEmail) => {
          if (
            currentSelectedEmail &&
            emailsWithHTMLContent.some(
              (email) => email._id === currentSelectedEmail._id
            )
          ) {
            return currentSelectedEmail;
          } else {
            return emailsWithHTMLContent[0] || null;
          }
        });
      } else {
        console.log('No emails found for this request, retrying...');
        setEmails([]);
        setSelectedEmail(null);
        setTimeout(() => fetchEmails(userId, requestId), 5000);
      }
    } catch (error) {
      console.error('Error fetching emails:', error);
      setEmails([]);
      setSelectedEmail(null);
      setTimeout(() => fetchEmails(userId, requestId), 5000);
    }
  };

  // --- SENDING EMAILS ---

  // Send a single email
  const sendEmail = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!selectedEmail) return;

    try {
      await axios.post('/api/send-email', {
        emailId: selectedEmail._id,
        to: selectedEmail.to,
        subject: selectedEmail.subject,
        content: selectedEmail.content,
        userId: user._id,
      });

      alert('Email sent successfully!');
      fetchEmails(user._id, lastRequestId);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  }, [selectedEmail, lastRequestId]);

  // Send all emails
  const sendAllEmails = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      await axios.post('/api/send-all-emails', {
        userId: user._id,
        emails, // Send the updated emails
      });

      alert('All emails sent successfully!');
      fetchEmails(user._id, lastRequestId);
    } catch (error) {
      console.error('Error sending all emails:', error);
      alert('Failed to send all emails.');
    }
  }, [emails, lastRequestId]);

  // Retrieve the Gmail auth URL for a single email
  const fetchAuthUrl = async () => {
    try {
      if (!selectedEmail) {
        alert('No email selected!');
        return;
      }
      const { to, subject, content, _id } = selectedEmail;
      const user = JSON.parse(localStorage.getItem('user'));

      const response = await axios.get(
        `/api/auth/gmail?to=${encodeURIComponent(to)}&subject=${encodeURIComponent(
          subject
        )}&content=${encodeURIComponent(content)}&userId=${encodeURIComponent(
          user._id
        )}&emailId=${encodeURIComponent(_id)}`
      );
      const authUrl = response.data.authUrl;
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error fetching Gmail auth URL:', error);
      alert('Failed to fetch Gmail authorization URL.');
    }
  };

  // Retrieve the Gmail auth URL for sending ALL emails
  const fetchAuthUrlForAuth = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await axios.get(
        `/api/auth/gmail?userId=${encodeURIComponent(user._id)}`
      );
      const authUrl = response.data.authUrl;
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error fetching Gmail auth URL:', error);
      alert('Failed to fetch Gmail authorization URL.');
    }
  };

  // Handler to send a single email, checking if user is authenticated
  const handleSendEmail = () => {
    if (!authenticated) {
      localStorage.setItem('pendingAction', 'sendEmail');
      fetchAuthUrl();
    } else {
      sendEmail();
    }
  };

  // Handler to send all emails, checking if user is authenticated
  const handleSendAllEmails = () => {
    if (!authenticated) {
      localStorage.setItem('pendingAction', 'sendAllEmails');
      fetchAuthUrlForAuth();
    } else {
      sendAllEmails();
    }
  };

  // Navigation (not strictly necessary if you just want next/prev)
  const handlePrevEmail = () => {
    if (!emails || emails.length === 0) return;
    const currentIndex = emails.findIndex(
      (email) => email._id === selectedEmail._id
    );
    const prevIndex = (currentIndex - 1 + emails.length) % emails.length;
    setSelectedEmail(emails[prevIndex]);
  };

  const handleNextEmail = () => {
    if (!emails || emails.length === 0) return;
    const currentIndex = emails.findIndex(
      (email) => email._id === selectedEmail._id
    );
    const nextIndex = (currentIndex + 1) % emails.length;
    setSelectedEmail(emails[nextIndex]);
  };

  // Editing subject/content
  const handleEmailFieldChange = (field, value) => {
    setSelectedEmail((prevEmail) => ({
      ...prevEmail,
      [field]: value,
    }));
  };

  const handleContentBlur = () => {
    if (contentEditableRef.current) {
      const updatedContent = contentEditableRef.current.innerHTML;
      handleEmailFieldChange('content', updatedContent);
    }
    setIsEditingContent(false);
  };

  // Channel expansion
  const handleToggleChannel = (channelName) => {
    setExpandedChannels((prevState) => ({
      ...prevState,
      [channelName]: !prevState[channelName],
    }));
  };

  // Youtuber / Brand selection (for smaller screens)
  const handleYoutuberSelect = (channelName) => {
    setSelectedYoutuber(channelName);
    setIsYoutuberPopupOpen(false);
  };

  const handleBrandSelect = (brandName, email) => {
    setSelectedBrand(brandName);
    setSelectedEmail(email);
    setIsBrandPopupOpen(false);
  };

  // --- RENDER ---

  return (
    <div className="root-container">
      <Header activePage="leads" />
      <div className="emails-container">
        {isSmallScreen ? (
          // Boxes for smaller screens
          <div className="youtuber-brand-boxes">
            <div className="youtuber-box" onClick={() => setIsYoutuberPopupOpen(true)}>
              <div className="box-placeholder">
                {selectedYoutuber || 'Choose Youtuber'}
              </div>
            </div>
            <div className="brand-box" onClick={() => setIsBrandPopupOpen(true)}>
              <div className="box-placeholder">{selectedBrand || 'Choose Brand'}</div>
            </div>
          </div>
        ) : (
          // Sidebar for larger screens
          emails.length > 0 && (
          <div className="youtuber-sidebar">
            {Object.keys(groupedEmails).map((channelName) => (
              <div
                key={channelName}
                className={`youtuber-item ${expandedChannels[channelName] ? 'active' : ''}`}
                onClick={() =>
                  setExpandedChannels({ [channelName]: true }) // Only one channel active at a time
                }
              >
                {channelName}
              </div>
            ))}
          </div>

        ))}

        {/* Youtuber Popup */}
        {isYoutuberPopupOpen && (
          <div className="popup-overlay" onClick={() => setIsYoutuberPopupOpen(false)}>
            <div className="popup-modal" onClick={(e) => e.stopPropagation()}>
              <h3>Choose Youtuber</h3>
              <div className="popup-options">
                {Object.keys(groupedEmails).map((channelName) => (
                  <div
                    key={channelName}
                    className="popup-option"
                    onClick={() => handleYoutuberSelect(channelName)}
                  >
                    {channelName}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Brand Popup */}
        {isBrandPopupOpen && (
          <div className="popup-overlay" onClick={() => setIsBrandPopupOpen(false)}>
            <div className="popup-modal" onClick={(e) => e.stopPropagation()}>
              <h3>Choose Brand</h3>
              <div className="popup-options">
                {groupedEmails[selectedYoutuber]?.map((email) => (
                  <div
                    key={email._id}
                    className="popup-option"
                    onClick={() => handleBrandSelect(email.brandName, email)}
                  >
                    {email.brandName}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {emails.length === 0 ? (
          <div className="no-emails-container">
            <div className="no-emails-message">
              <h2>
                You've sent all emails. We will notify you when new emails are
                ready.
              </h2>
              <h3>
                Want more emails?{' '}
                <a href="/settings" className="upgrade-link">
                  Upgrade plan
                </a>
              </h3>
            </div>
          </div>
        ) : (
          <div className="brands-container">
            {Object.entries(groupedEmails).map(([channelName, emailsInChannel]) =>
              expandedChannels[channelName] ? (
                <div key={channelName} className="brand-list">
                  <div className="brand-items">
                    {emailsInChannel.map((email) => (
                      <div
                        key={email._id}
                        className={`brand-item ${selectedEmail && selectedEmail._id === email._id
                            ? 'selected'
                            : ''
                          }`}
                        onClick={() => setSelectedEmail(email)}
                      >
                        {email.brandName}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null
            )}
          </div>
        )}

        {selectedEmail && (
          <div className="email-card-wrapper">
            <div className="email-card">
              <div className="email-section">
                <label>To</label>
                <div className="email-input-container">
                  <div className="email-input">{selectedEmail.to}</div>
                </div>
              </div>
              <div className="email-section">
                <label>Subject</label>
                <div className="email-input-container">
                  {isEditingSubject ? (
                    <input
                      type="text"
                      className="email-input"
                      value={selectedEmail.subject}
                      onChange={(e) =>
                        handleEmailFieldChange('subject', e.target.value)
                      }
                      onBlur={() => setIsEditingSubject(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setIsEditingSubject(false);
                        }
                      }}
                    />
                  ) : (
                    <div
                      className="email-input"
                      onClick={() => setIsEditingSubject(true)}
                    >
                      {selectedEmail.subject}
                    </div>
                  )}
                </div>
              </div>
              <div className="email-section">
                <label>Content</label>
                <div className="email-textarea-container">
                  {isEditingContent ? (
                    <div
                      className="email-textarea"
                      contentEditable
                      ref={contentEditableRef}
                      onBlur={handleContentBlur}
                      suppressContentEditableWarning
                      dangerouslySetInnerHTML={{
                        __html: selectedEmail.content,
                      }}
                    />
                  ) : (
                    <div
                      className="email-textarea"
                      onClick={() => setIsEditingContent(true)}
                      dangerouslySetInnerHTML={{
                        __html: selectedEmail.content,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="email-buttons">
              <button
                className="email-button send-email"
                onClick={handleSendEmail}
              >
                Send Email
              </button>

            </div>
          </div>
        )}
      </div>
    </div>
  );

}

export default Leads;