import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import './ThreePanels.css';
import Header from './Header';
import ThreePanels from './ThreePanels';
import { marked } from 'marked';

const Leads = () => {
  const [lastRequest, setLastRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedYoutuber, setSelectedYoutuber] = useState(0);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [emailData, setEmailData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(() => {
    // Initialize selectedBrand from localStorage
    const savedBrand = localStorage.getItem('selectedBrand');
    return savedBrand ? JSON.parse(savedBrand) : null;
  });
  const [activeTab, setActiveTab] = useState(() => {
    // Initialize activeTab from localStorage
    return localStorage.getItem('activeTab') || 'brand-data';
  });
  const [leadsSearch, setLeadsSearch] = useState('');

  // Save selectedBrand to localStorage whenever it changes
  useEffect(() => {
    if (selectedBrand) {
      localStorage.setItem('selectedBrand', JSON.stringify(selectedBrand));
    } else {
      localStorage.removeItem('selectedBrand');
    }
  }, [selectedBrand]);

  // Save activeTab to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  // Reuse the same data fetching logic from Leads2
  useEffect(() => {
    const fetchLastRequest = async () => {
      try {
        let userData = localStorage.getItem('user');
        
        if (!userData) {
          if (localStorage.getItem('rubybeam_user')) userData = localStorage.getItem('rubybeam_user');
          if (!userData && localStorage.getItem('userData')) userData = localStorage.getItem('userData');
          if (!userData && localStorage.getItem('currentUser')) userData = localStorage.getItem('currentUser');
        }

        let user;
        try {
          user = userData ? JSON.parse(userData) : null;
        } catch (parseError) {
          setError('Error parsing user data from localStorage');
          setLoading(false);
          return;
        }

        if (!user || !user._id || !user.token) {
          setError('Please log in to view your API requests');
          setLoading(false);
          return;
        }

        const primaryUrl = `https://rubybeam.com/api/last-request/${user._id}`;
        
        try {
          const response = await axios({
            method: 'get',
            url: primaryUrl,
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          
          if (response.data && Object.keys(response.data).length > 0) {
            setLastRequest(response.data);
            // Extract email data from the response
            if (response.data.response?.response_body?.results) {
              const allEmails = response.data.response.response_body.results.reduce((acc, result) => {
                if (result.emails) {
                  return [...acc, ...result.emails];
                }
                return acc;
              }, []);
              setEmailData(allEmails);
            }
          } else {
            setLastRequest(null);
          }
        } catch (axiosError) {
          setError(axiosError.response?.data?.error || 'Failed to fetch API requests');
        }
      } catch (err) {
        setError('An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    setTimeout(fetchLastRequest, 500);
  }, []);

  // Process youtubers data
  const youtubers = useMemo(() => {
    if (!lastRequest?.response?.response_body?.results) {
      return [];
    }
    
    const results = lastRequest.response.response_body.results;
    
    return results.map((result, index) => {
      // Filter brands to only include those with emails
      const brandsWithEmails = result.brands_to_email.filter(brand => {
        // Find matching email data from the emails array
        const matchingEmail = result.emails.find(email => 
          email.brandName === brand.brand_name
        );
        return matchingEmail && matchingEmail.to; // Only include if there's an email
      });

      // Map the filtered brands with their email data
      const mappedBrands = brandsWithEmails.map(brand => {
        const matchingEmail = result.emails.find(email => 
          email.brandName === brand.brand_name
        );
        // Extract domain from email address
        const emailDomain = matchingEmail?.to?.split('@')[1];
        const logoUrl = emailDomain ? `https://logo.clearbit.com/${emailDomain}` : 'https://placehold.co/64x64/png';

        return {
          ...brand,
          outreach_email: matchingEmail.to,
          subject: matchingEmail.subject,
          email_template: marked(matchingEmail.content, { breaks: true }), // Convert markdown to HTML
          logo_url: logoUrl // Override the logo_url with Clearbit logo
        };
      });

      return {
        url: result.url,
        name: result.channel_info?.name || `Channel ${index + 1}`,
        description: result.channel_info?.description || 'No description available',
        youtuberProfile: result.youtuber_profile?.youtuber_profile || 'No profile available',
        personas: result.youtuber_profile?.personas || [],
        brands: mappedBrands
      };
    });
  }, [lastRequest]);

  // Handle selecting a youtuber
  const handleSelectYoutuber = (index) => {
    setSelectedYoutuber(index);
    // Reset brand selection and active tab when switching youtubers
    setSelectedBrand(null);
    setActiveTab('brand-data');
    setSelectedPersona(null);
  };

  // Handle selecting a brand
  const handleSelectBrand = (brand) => {
    setSelectedBrand(brand);
  };

  // Handle going back to brands grid
  const handleBackToBrands = () => {
    setSelectedBrand(null);
    setActiveTab('brand-data');
    setLeadsSearch('');
  };

  // Handle selecting a persona
  const handleSelectPersona = (persona) => {
    // If clicking the same persona that's already selected, close it
    if (selectedPersona?.persona === persona.persona) {
      setSelectedPersona(null);
    } else {
      // Otherwise, select the new persona
      setSelectedPersona(persona);
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;
  if (!lastRequest) return <div className="no-data">No API requests found for this user</div>;
  if (youtubers.length === 0) return <div className="no-data">No youtubers found in the response</div>;

  const selectedYoutuberData = youtubers[selectedYoutuber];

  // Prepare content for creator info panel
  const creatorInfoContent = (
    <div className="creator-info-container">
      {/* Profile Section */}
      <div className="creator-profile-section">
        <div className="profile-image-container">
          <img 
            src={lastRequest?.response?.response_body?.results[selectedYoutuber]?.image || 'https://placehold.co/120x120/png'} 
            alt="Creator profile"
            className="profile-image"
            onError={(e) => {
              e.target.src = 'https://placehold.co/120x120/png';
            }}
          />
        </div>
        <div className="profile-name">
          {selectedYoutuberData.name}
        </div>
        <div className="profile-content" style={{ textAlign: 'center' }}>
          <p>{selectedYoutuberData.youtuberProfile}</p>
        </div>
      </div>

      {/* Personas Section */}
      <div className="creator-personas-section">
        <h3>Personas</h3>
        <div className="personas-grid">
          {selectedYoutuberData.personas.map((persona, index) => (
            <div key={index}>
              <button
                className="persona-button"
                onClick={() => handleSelectPersona(persona)}
              >
                <span>{persona.persona}</span>
              </button>
              <div className={`persona-details-section ${selectedPersona?.persona === persona.persona ? 'expanded' : ''}`}>
                <div className="persona-card">
                  <div className="persona-details">
                    <div className="detail-item">
                      <span className="detail-label">Age:</span>
                      <span className="detail-value">{persona.age}</span>
                    </div>
                    <div className="detail-item">
                      <span className="detail-label">Occupation:</span>
                      <span className="detail-value">{persona.occupation}</span>
                    </div>
                    <div className="detail-item">
                      <span className="detail-label">Interests:</span>
                      <span className="detail-value">{persona.interests}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="three-panels-page">
      <Header activePage="leads" />
      <ThreePanels
        youtubers={youtubers}
        selectedYoutuber={selectedYoutuber}
        onSelectYoutuber={handleSelectYoutuber}
        leads={selectedYoutuberData.brands}
        creatorInfo={creatorInfoContent}
        onSelectBrand={handleSelectBrand}
        onBackToBrands={handleBackToBrands}
        selectedBrand={selectedBrand}
        emailData={emailData}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
    </div>
  );
};

export default Leads; 