import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from './assets/animation.json';
import './IntroSection.css';

function safeParse(value) {
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error('Error parsing JSON from localStorage in IntroSection:', error);
    return null;
  }
}

function IntroSection() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const stored = localStorage.getItem('user');
    if (stored) {
      const parsed = safeParse(stored);
      setUser(parsed);
    }
  }, []);

  const handleClick = () => {
    if (user) {
      navigate('/generate');
    } else {
      navigate('/auth?mode=signup', { state: { redirectTo: '/generate' } });
    }
  };

  return (
    <div className="intro-section">
      {/* Illustration Container */}
      <div className="illustration-container">
        <div className="lottie-wrapper">
          <Player autoplay loop src={animationData} className="lottie-player" />
        </div>
      </div>
      {/* Content Section */}
      <div className="content">
        <h2 className="homepage-h2">Never Miss a Sponsorship Deal Again</h2>
        <p className="intro-text">
        Rubybeam is an AI agent that finds high-value brand deals perfectly matched to your creators' audience and style. Get a steady stream of verified sponsorships automatically.        
        </p>
        {/* CTA Section */}
        <div className="cta-section">
          <button className="cta-button" onClick={handleClick}>
            {user ? 'Generate Leads' : 'Generate 1 Lead for Free'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default IntroSection;