import React, { useState, useEffect, useMemo } from 'react';
import './ThreePanels.css';
import { marked } from 'marked';
import axios from 'axios';

// Search icon component
const SearchIcon = () => (
  <svg className="search-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="currentColor" />
  </svg>
);

const ThreePanels = ({
  youtubers,
  selectedYoutuber,
  onSelectYoutuber,
  leads,
  creatorInfo,
  onSelectBrand,
  onBackToBrands,
  selectedBrand,
  emailData,
  activeTab,
  onTabChange
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const brandsPerPage = 5;
  const [brandDataExpanded, setBrandDataExpanded] = useState(false);
  const [outreachExpanded, setOutreachExpanded] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const [currentContactIndex, setCurrentContactIndex] = useState(0);
  const [contactLoading, setContactLoading] = useState(false);
  const [contactError, setContactError] = useState(null);
  const [creatorSearch, setCreatorSearch] = useState('');
  const [leadsSearch, setLeadsSearch] = useState('');
  const [contactSectionCollapsed, setContactSectionCollapsed] = useState(false);
  const [pitchEmailSectionCollapsed, setPitchEmailSectionCollapsed] = useState(false);

  // Save selectedBrand to localStorage whenever it changes
  useEffect(() => {
    if (selectedBrand) {
      localStorage.setItem('selectedBrand', JSON.stringify(selectedBrand));
    } else {
      localStorage.removeItem('selectedBrand');
    }
  }, [selectedBrand]);

  // Restore selectedBrand from localStorage on component mount
  useEffect(() => {
    const savedBrand = localStorage.getItem('selectedBrand');
    if (savedBrand) {
      try {
        const parsedBrand = JSON.parse(savedBrand);
        onSelectBrand(parsedBrand);
      } catch (error) {
        console.error('Error restoring selected brand:', error);
        localStorage.removeItem('selectedBrand');
      }
    }
  }, []); // Empty dependency array means this runs once on mount

  // Filter youtubers based on search
  const filteredYoutubers = useMemo(() => {
    if (!creatorSearch.trim()) return youtubers;
    const searchTerm = creatorSearch.toLowerCase();
    return youtubers.filter(youtuber =>
      youtuber.name?.toLowerCase().includes(searchTerm)
    );
  }, [youtubers, creatorSearch]);

  // Filter leads based on search
  const filteredLeads = useMemo(() => {
    if (!leadsSearch.trim()) return leads;
    const searchTerm = leadsSearch.toLowerCase();
    return leads.filter(brand =>
      brand.brand_name?.toLowerCase().includes(searchTerm)
    );
  }, [leads, leadsSearch]);

  // Fetch contact details when brand changes
  useEffect(() => {
    const fetchContactDetails = async () => {
      if (!selectedBrand) return;

      setContactLoading(true);
      setContactError(null);
      try {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData || !userData.token) {
          setContactError('Authentication token missing');
          return;
        }

        const response = await axios.get(`https://rubybeam.com/api/email-contacts?brand=${encodeURIComponent(selectedBrand.brand_name)}&rank=1`, {
          headers: {
            'Authorization': `Bearer ${userData.token}`
          }
        });

        // Ensure we're handling an array of contacts
        let contacts;
        if (Array.isArray(response.data)) {
          contacts = response.data;
        } else if (response.data && typeof response.data === 'object') {
          contacts = response.data.contacts || [response.data];
        } else {
          contacts = [];
        }

        if (contacts.length === 0) {
          setContactError('No contacts found');
          return;
        }

        setContactDetails(contacts);
        setCurrentContactIndex(0); // Reset to first contact when brand changes
      } catch (error) {
        setContactError(error.response?.data?.error || 'Failed to fetch contact details');
      } finally {
        setContactLoading(false);
      }
    };

    fetchContactDetails();
  }, [selectedBrand]);

  // Handle contact navigation
  const handlePrevContact = (e) => {
    e.stopPropagation(); // Prevent section toggle
    setCurrentContactIndex(prev =>
      prev > 0 ? prev - 1 : contactDetails.length - 1
    );
  };

  const handleNextContact = (e) => {
    e.stopPropagation(); // Prevent section toggle
    setCurrentContactIndex(prev =>
      prev < contactDetails.length - 1 ? prev + 1 : 0
    );
  };

  // Get current contact
  const currentContact = contactDetails[currentContactIndex];

  // Update selectedEmailData to use current contact's email and name
  const selectedEmailData = useMemo(() => {
    if (!selectedBrand || !emailData) return null;
    const email = emailData.find(email => email.brandName === selectedBrand.brand_name);
    if (email) {
      // Get first name from current contact
      const firstName = currentContact?.name?.split(' ')[0] || '';

      // Split content into lines and remove the first line
      let content = email.content.split('\n').slice(1).join('\n');

      // Add new greeting with the contact's first name
      const greeting = firstName ? `Hi ${firstName},\n\n` : 'Hi,\n\n';
      content = greeting + content;

      return {
        ...email,
        to: currentContact?.email || email.to,
        content: marked(content, { breaks: true }) // Convert markdown to HTML
      };
    }
    return null;
  }, [selectedBrand, emailData, currentContact]);

  const totalPages = Math.ceil(leads.length / brandsPerPage);
  const startIndex = currentPage * brandsPerPage;
  const visibleBrands = leads.slice(startIndex, startIndex + brandsPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(0, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(totalPages - 1, prev + 1));
  };

  const toggleContactSection = () => {
    setContactSectionCollapsed(!contactSectionCollapsed);
  };

  const togglePitchEmailSection = () => {
    setPitchEmailSectionCollapsed(!pitchEmailSectionCollapsed);
  };

  return (
    <div className="three-panels-container">
      {/* Left Panel - Youtubers (15%) */}
      <div className="panel youtubers-panel">
        <h2 className="panel-title">Creators</h2>
        <div className="search-container">
          <SearchIcon />
          <input
            type="text"
            className="search-input"
            placeholder="Search"
            value={creatorSearch}
            onChange={(e) => setCreatorSearch(e.target.value)}
          />
        </div>
        <div className="youtubers-list">
          {filteredYoutubers.map((youtuber, index) => (
            <div
              key={index}
              className={`youtuber-item ${selectedYoutuber === index ? 'active' : ''}`}
              onClick={() => onSelectYoutuber(index)}
            >
              {youtuber.name || `Channel ${index + 1}`}
            </div>
          ))}
        </div>
      </div>

      {/* Middle Panel - Leads (55%) */}
      <div className="panel leads-panel">
        <div className="panel-title">
          {selectedBrand ? (
            <div className="brand-details-header">
              <button className="back-button" onClick={() => onBackToBrands()}>
                <span className="back-arrow">←</span>
              </button>
              <h3 className="brand-name-header">{selectedBrand.brand_name}</h3>
            </div>
          ) : (
            "Leads"
          )}
        </div>
        {selectedBrand ? (
          <div className="brand-content">
            <div className="brand-tabs">
              <button
                className={`tab-button ${activeTab === 'brand-data' ? 'active' : ''}`}
                onClick={() => onTabChange('brand-data')}
              >
                Brand Overview
              </button>
              <button
                className={`tab-button ${activeTab === 'outreach' ? 'active' : ''}`}
                onClick={() => onTabChange('outreach')}
              >
                Outreach
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 'brand-data' ? (
                <div className="brand-overview">
                  <div className="brand-overview-section">
                    <h3>Company Description</h3>
                    <p>{selectedBrand.company_description}</p>
                  </div>
                  <div className="brand-overview-section">
                    <h3>Why It's a Good Fit</h3>
                    <p>{selectedBrand.why_it_fits || 'No fit information available'}</p>
                  </div>
                  <div className="brand-overview-section">
                    <h3>Related Persona</h3>
                    <p>{selectedBrand.persona_name}</p>
                  </div>
                  <div className="brand-overview-section">
                    <h3>Focus</h3>
                    {selectedBrand.focus && selectedBrand.focus.focus_point ? (
                      <>
                        <p><strong>{selectedBrand.focus.focus_point.focus.charAt(0).toUpperCase() + selectedBrand.focus.focus_point.focus.slice(1)}{!selectedBrand.focus.focus_point.focus.endsWith('.') ? '.' : ''}</strong></p>
                        <p>{selectedBrand.focus.focus_point.description.charAt(0).toUpperCase() + selectedBrand.focus.focus_point.description.slice(1)}{!selectedBrand.focus.focus_point.description.endsWith('.') ? '.' : ''}</p>
                      </>
                    ) : (
                      <p>No focus points available</p>
                    )}
                  </div>
                  <div className="brand-overview-section">
                    <h3>Marketing Objective</h3>
                    {selectedBrand.objective ? (
                      <>
                        <p><strong>{selectedBrand.objective.objective.charAt(0).toUpperCase() + selectedBrand.objective.objective.slice(1)}{!selectedBrand.objective.objective.endsWith('.') ? '.' : ''}</strong></p>
                        <p>{selectedBrand.objective.description.charAt(0).toUpperCase() + selectedBrand.objective.description.slice(1)}{!selectedBrand.objective.description.endsWith('.') ? '.' : ''}</p>
                      </>
                    ) : (
                      <p>No objectives available</p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="outreach-content">
                  <div className="brand-info-section">
                    <h3
                      className={contactSectionCollapsed ? 'collapsed' : ''}
                      onClick={toggleContactSection}
                    >
                      Contact Person
                    </h3>
                    <div className={`section-content ${contactSectionCollapsed ? 'collapsed' : ''}`}>
                      {contactLoading ? (
                        <p>Loading contact details...</p>
                      ) : contactError ? (
                        <p>Error: {contactError}</p>
                      ) : contactDetails.length > 0 ? (
                        <>
                          <p><strong>Name:</strong> {currentContact.name}</p>
                          <p><strong>Role:</strong> {currentContact.role}</p>
                          <p>
                            <strong>LinkedIn:</strong>{' '}
                            <a href={currentContact.linkedin} target="_blank" rel="noopener noreferrer" className="contact-link">
                              View Profile
                            </a>
                          </p>
                          <p><strong>Email:</strong> {currentContact.email}</p>
                          {contactDetails.length > 1 && (
                            <div className="contact-navigation">
                              <button
                                className={`contact-nav-button ${currentContactIndex === 0 ? 'disabled' : ''}`}
                                onClick={handlePrevContact}
                                title="Previous contact"
                                disabled={currentContactIndex === 0}
                              >
                                ◀
                              </button>
                              <span className="contact-counter">
                                {currentContactIndex + 1}/{contactDetails.length}
                              </span>
                              <button
                                className={`contact-nav-button ${currentContactIndex === contactDetails.length - 1 ? 'disabled' : ''}`}
                                onClick={handleNextContact}
                                title="Next contact"
                                disabled={currentContactIndex === contactDetails.length - 1}
                              >
                                ▶
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <p>No contact details available</p>
                      )}
                    </div>
                  </div>
                  <div className="brand-info-section">
                    <h3
                      className={pitchEmailSectionCollapsed ? 'collapsed' : ''}
                      onClick={togglePitchEmailSection}
                    >
                      Pitch Email
                    </h3>
                    <div className={`section-content ${pitchEmailSectionCollapsed ? 'collapsed' : ''}`}>
                      <div className="tp-email-card-wrapper">
                        <div className="tp-email-card">
                          <div className="tp-email-section">
                            <div className="tp-email-input-container">
                              <div className="tp-email-input">{selectedEmailData?.to || 'No email address available'}</div>
                            </div>
                          </div>
                          <div className="tp-email-section">
                            <div className="tp-email-input-container">
                              <div className="tp-email-input">{selectedEmailData?.subject || 'No subject available'}</div>
                            </div>
                          </div>
                          <div className="tp-email-section">
                            <div className="tp-email-textarea-container">
                              <div
                                className="tp-email-textarea"
                                dangerouslySetInnerHTML={{
                                  __html: selectedEmailData?.content || 'No content available'
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="search-container">
              <SearchIcon />
              <input
                type="text"
                className="search-input"
                placeholder="Search"
                value={leadsSearch}
                onChange={(e) => setLeadsSearch(e.target.value)}
              />
            </div>
            <div className="leads-grid">
              {filteredLeads.map((brand, index) => (
                <div
                  key={index}
                  className="brand-card"
                  onClick={() => {
                    onSelectBrand(brand);
                    onTabChange('brand-data');
                  }}
                >
                  <img
                    src={brand.logo_url || 'https://placehold.co/64x64/png'}
                    alt={`${brand.brand_name} logo`}
                    className="brand-logo"
                    onError={(e) => {
                      e.target.src = 'https://placehold.co/64x64/png';
                    }}
                  />
                  <div className="brand-name">{brand.brand_name}</div>
                  <button className="view-button">View</button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Right Panel - Creator Info (30%) */}
      <div className="panel creator-info-panel">
        <h2 className="panel-title">Profile</h2>
        <div className="creator-info-content">
          {creatorInfo}
        </div>
      </div>
    </div>
  );
};

export default ThreePanels;