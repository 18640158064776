import React from 'react';
import { Link } from 'react-router-dom';
import './Blog.css';
import BlogHeader from './BlogHeader';

// Function to create URL-friendly slug from title
const createSlug = (title) => {
  return title
    .toLowerCase()
    .split(' ')
    .slice(0, 4) // Take first 4 words
    .join('-')
    .replace(/[^a-z0-9-]/g, ''); // Remove special characters
};

const blogData = [
  {
    id: 1,
    title: "Why YouTube Is the Best Platform for Brand Sponsorship and Influencer Campaigns",
    excerpt: "YouTube stands out as the best platform for sponsorships due to its niche-specific audience, search-based discovery, and storytelling potential...",
    image: process.env.PUBLIC_URL + '/blog_post_1.png',
    slug: "why-youtube-is-the-best"
  },
  {
    id: 2,
    title: "The Most Important KPI in Influencer Marketing That Most Brands Ignore",
    excerpt: "Why Most Brands Focus on the Wrong Influencer Marketing KPIs. When running influencer campaigns, brands often track multiple KPIs, such as...",
    image: process.env.PUBLIC_URL + '/blog_post_2.png',
    slug: "the-most-important-kpi"
  },
];

const Blog = () => {
  return (
    <div className="blog-container">
      <BlogHeader />
      <div className="blog-grid">
        {blogData.map((post) => (
          <Link to={`/blog/${post.slug}`} key={post.id} className="blog-item">
            <img src={post.image} alt={post.title} />
            <h2>{post.title}</h2>
            <p>{post.excerpt}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blog;
