import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Pricing.css';
import Header from './Header';
import UserContext from './UserContext'; // Import your UserContext

function Pricing() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext); // Retrieve the user from context (or localStorage/session)

  useEffect(() => {
    document.body.classList.add('pricing-page');
    return () => document.body.classList.remove('pricing-page');
  }, []);

  // Agency plans
  const agencyPlans = [
    { name: 'Starter', price: 39, emails: 5, youtubers: 10 },
    { name: 'Growth', price: 69, emails: 5, youtubers: 20 },
    { name: 'Pro', price: 99, emails: 5, youtubers: 40 },
  ];

  const handleGetStarted = (plan) => {
    if (user) {
      // User is logged in, redirect to Billing page
      navigate('/billing', { state: { plan } });
    } else {
      // User is not logged in, redirect to Sign-Up page and pass the plan and redirectTo
      navigate('/auth', { state: { redirectTo: '/pricing', plan } });
    }
  };

  return (
    <div className="page-container">
      <Header activePage="pricing" />
      <h2 className="intro-h2">
      Always-On Sponsorship Lead Discovery, <br></br>Powered by Your Creators' Audience and Content.
      </h2>
      <p className="intro-p">
      Rubybeam continuously scans the web for sponsorship deals that match your creators' audience and content. When a new opportunity arises, you get notified instantly with the details.      </p>
      <div className="cards-container">
        {agencyPlans.map((plan) => (
          <div className="card" key={plan.name}>
            <h2>{plan.name}</h2>
            <h4 className="email-amount">
              {`${plan.youtubers} YouTubers`}
            </h4>
            <h3 className="price">{`$${plan.price}/mo`}</h3>
            <button
              className="buy-button"
              onClick={() => handleGetStarted(plan)} // Redirects based on login status
            >
              Get Started
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;