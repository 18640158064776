import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import './Leads2.css';
import Header from './Header';

const Leads2 = () => {
  const [lastRequest, setLastRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serverLogs, setServerLogs] = useState(null);
  const [debugInfo, setDebugInfo] = useState(null);
  const [selectedYoutuber, setSelectedYoutuber] = useState(0); // Index of selected youtuber
  const [expandedChannels, setExpandedChannels] = useState({});
  const [creatorInfoExpanded, setCreatorInfoExpanded] = useState(false);
  const [leadsExpanded, setLeadsExpanded] = useState(false);
  const [profileExpanded, setProfileExpanded] = useState(false);
  const [personasExpanded, setPersonasExpanded] = useState(false);
  const [expandedBrands, setExpandedBrands] = useState({});

  // Filter and organize youtubers from response data
  const youtubers = useMemo(() => {
    if (!lastRequest || !lastRequest.response || !lastRequest.response.response_body || !lastRequest.response.response_body.results) {
      return [];
    }
    
    return lastRequest.response.response_body.results.map((result, index) => {
      // Extract personas from the CORRECT location - they are nested in youtuber_profile.personas
      let personas = [];
      
      if (result.youtuber_profile && result.youtuber_profile.personas && Array.isArray(result.youtuber_profile.personas)) {
        personas = result.youtuber_profile.personas;
      } else {
        // Default personas if none found
        personas = [
          {
            name: `Persona 1 for ${result.channel_info?.name || 'Unknown'}`,
            age: "18-35",
            occupation: "Default occupation",
            interests: "Default interests"
          },
          {
            name: `Persona 2 for ${result.channel_info?.name || 'Unknown'}`,
            age: "25-45",
            occupation: "Another default occupation",
            interests: "More default interests"
          }
        ];
      }
      
      // Extract brands data with all relevant information
      let brands = [];
      if (result.brands_to_email && Array.isArray(result.brands_to_email)) {
        brands = result.brands_to_email.map(brand => {
          // Extract the correct field names from the API response
          const extractedName = brand.brand_name || brand.company_name || brand.name || "Unknown Brand";
          let extractedDescription = "No description available";
          let extractedFocus = [];
          let extractedMarketingObjective = "No marketing objective specified";
          let extractedOutreachEmail = "No email template available";
          let extractedRelatedPersona = "General Audience";
          
          // Safely extract description
          if (typeof brand.company_description === 'string') {
            extractedDescription = brand.company_description;
          } else if (typeof brand.description === 'string') {
            extractedDescription = brand.description;
          }
          
          // Safely extract focus points
          if (brand.focus && brand.focus.focus_point) {
            extractedFocus = [{
              point: brand.focus.focus_point.focus,
              description: brand.focus.focus_point.description
            }];
          } else if (Array.isArray(brand.focus_points)) {
            extractedFocus = brand.focus_points;
          } else if (brand.focus_points) {
            extractedFocus = [brand.focus_points];
          }
          
          // Safely extract marketing objective
          if (typeof brand.marketing_objective === 'string') {
            extractedMarketingObjective = brand.marketing_objective;
          } else if (typeof brand.objective === 'string') {
            extractedMarketingObjective = brand.objective;
          } else if (brand.marketing_objective || brand.objective) {
            extractedMarketingObjective = brand.marketing_objective || brand.objective;
          }
          
          // Safely extract outreach email
          if (typeof brand.email_template === 'string') {
            extractedOutreachEmail = brand.email_template;
          } else if (typeof brand.outreach_email === 'string') {
            extractedOutreachEmail = brand.outreach_email;
          }
          
          // Safely extract related persona
          if (typeof brand.persona_name === 'string') {
            extractedRelatedPersona = brand.persona_name;
          } else if (typeof brand.related_persona === 'string') {
            extractedRelatedPersona = brand.related_persona;
          }
          
          return {
            name: extractedName,
            emailCount: 1,
            description: extractedDescription,
            focus: extractedFocus,
            marketingObjective: extractedMarketingObjective,
            outreachEmail: extractedOutreachEmail,
            relatedPersona: extractedRelatedPersona
          };
        });
      } else {
        // Default brands if none found
        brands = [
          { 
            name: `Default Brand 1 for ${result.channel_info?.name || 'Unknown'}`, 
            emailCount: 1,
            description: "This is a sample brand description. It would normally contain information about the company, products, and services.",
            focus: [
              { point: "Quality", description: "Focus on high-quality products" },
              { point: "Innovation", description: "Emphasize innovative approaches" }
            ],
            marketingObjective: "Increase brand awareness and reach new audiences",
            outreachEmail: "Dear [Contact],\n\nI hope this email finds you well. I'm reaching out because I believe there's an opportunity for collaboration...",
            relatedPersona: "Adventure Seekers"
          },
          { 
            name: `Default Brand 2 for ${result.channel_info?.name || 'Unknown'}`, 
            emailCount: 1,
            description: "Another sample brand with different characteristics and focus areas.",
            focus: [
              { point: "Sustainability", description: "Highlight eco-friendly initiatives" }
            ],
            marketingObjective: "Promote new product line and drive sales",
            outreachEmail: "Hello [Contact],\n\nI'm a content creator in the [niche] space, and I've been following your brand for some time...",
            relatedPersona: "Minimalist Enthusiasts"
          }
        ];
      }
      
      return {
        url: result.url,
        name: result.channel_info?.name || `Unknown Channel ${index + 1}`,
        description: result.channel_info?.description || 'No description available',
        youtuberProfile: result.youtuber_profile?.youtuber_profile || 'No profile available',
        emails: result.emails || [],
        personas: personas,
        brands: brands
      };
    });
  }, [lastRequest]);

  // First useEffect to check for all users in localStorage immediately
  useEffect(() => {
    try {
      // Try to find any user data
      let userData = localStorage.getItem('user');
      
      if (!userData) {
        // Try alternate keys that might contain user data
        if (localStorage.getItem('userData')) userData = localStorage.getItem('userData');
        if (localStorage.getItem('currentUser')) userData = localStorage.getItem('currentUser');
        if (localStorage.getItem('auth')) userData = localStorage.getItem('auth');
      }
      
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          localStorage.setItem('rubybeam_user', JSON.stringify(parsedUser));
        } catch (e) {
          // Handle parse error silently
        }
      }
    } catch (err) {
      // Handle error silently
    }
  }, []);

  useEffect(() => {
    const fetchLastRequest = async () => {
      try {
        let userData = localStorage.getItem('user');
        
        if (!userData) {
          if (localStorage.getItem('rubybeam_user')) userData = localStorage.getItem('rubybeam_user');
          if (!userData && localStorage.getItem('userData')) userData = localStorage.getItem('userData');
          if (!userData && localStorage.getItem('currentUser')) userData = localStorage.getItem('currentUser');
        }

        let user;
        try {
          user = userData ? JSON.parse(userData) : null;
        } catch (parseError) {
          setError('Error parsing user data from localStorage');
          setLoading(false);
          return;
        }

        if (!user || !user._id || !user.token) {
          setError('Please log in to view your API requests');
          setLoading(false);
          return;
        }

        const primaryUrl = `https://rubybeam.com/api/last-request/${user._id}`;
        const fallbackUrl = `https://rubybeam.com/api/requests/last/${user._id}`;

        try {
          const response = await axios({
            method: 'get',
            url: primaryUrl,
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          
          if (response.data && Object.keys(response.data).length > 0) {
            setLastRequest(response.data);
            
            if (response.data.response?.response_body?.results?.length > 0) {
              const initialExpandedChannels = {};
              response.data.response.response_body.results.forEach((result, index) => {
                const channelName = result.channel_info?.name || `Channel ${index+1}`;
                initialExpandedChannels[channelName] = false;
              });
              setExpandedChannels(initialExpandedChannels);
              setSelectedYoutuber(0);
            }
          } else {
            setLastRequest(null);
          }
        } catch (axiosError) {
          if (axiosError.response?.status === 401) {
            try {
              const fallbackResponse = await axios.get(fallbackUrl);
              
              if (fallbackResponse.data && fallbackResponse.data.request) {
                setLastRequest(fallbackResponse.data.request);
                
                if (fallbackResponse.data.request.response?.response_body?.results?.length > 0) {
                  const initialExpandedChannels = {};
                  fallbackResponse.data.request.response.response_body.results.forEach((result, index) => {
                    const channelName = result.channel_info?.name || `Channel ${index+1}`;
                    initialExpandedChannels[channelName] = false;
                  });
                  setExpandedChannels(initialExpandedChannels);
                  setSelectedYoutuber(0);
                }
              } else {
                setError('No data found in fallback endpoint');
              }
            } catch (fallbackError) {
              setError('Authentication failed and fallback request also failed');
            }
          } else {
            setError(axiosError.response?.data?.error || 'Failed to fetch API requests');
          }
        }
      } catch (err) {
        setError('An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    setTimeout(fetchLastRequest, 500);
  }, []);

  // Handle selecting a youtuber
  const handleSelectYoutuber = (index) => {
    // If selecting a different youtuber than currently selected
    if (index !== selectedYoutuber) {
      console.log(`Switching from youtuber ${selectedYoutuber} to ${index}`);
      
      // First close all expandable sections to force UI reset
      setCreatorInfoExpanded(false);
      setProfileExpanded(false);
      setPersonasExpanded(false);
      setLeadsExpanded(false);
      
      // Clear expanded brands when switching youtubers
      setExpandedBrands({});
      
      // Then change the selected youtuber
      setSelectedYoutuber(index);
      
      // Update expanded state for channels in sidebar
      if (youtubers.length > 0) {
        const newExpandedChannels = {};
        youtubers.forEach((youtuber, idx) => {
          newExpandedChannels[youtuber.name] = idx === index;
        });
        setExpandedChannels(newExpandedChannels);
      }
      
      // After a short delay, re-open the Creator Info section
      setTimeout(() => {
        setCreatorInfoExpanded(true);
      }, 50);
    }
  };

  // Toggle Creator Info section expand/collapse
  const toggleCreatorInfoExpanded = () => {
    setCreatorInfoExpanded(!creatorInfoExpanded);
  };

  // Toggle Leads section expand/collapse
  const toggleLeadsExpanded = () => {
    setLeadsExpanded(!leadsExpanded);
  };

  // Toggle profile section expand/collapse
  const toggleProfileExpanded = () => {
    setProfileExpanded(!profileExpanded);
  };

  // Toggle personas section expand/collapse
  const togglePersonasExpanded = () => {
    setPersonasExpanded(!personasExpanded);
  };

  // Toggle brand expand/collapse
  const toggleBrandExpanded = (brandName) => {
    setExpandedBrands(prev => ({
      ...prev,
      [brandName]: !prev[brandName]
    }));
  };

  // Add this function at the top of the component, before the return statement
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (loading) return <div className="leads2-p4">Loading API requests...</div>;
  
  if (error) {
    return (
      <div className="leads2-p4">
        <div className="leads2-text-red-500">Error: {error}</div>
        {serverLogs && <div className="leads2-mt2 leads2-text-gray-700">Server message: {serverLogs}</div>}
        {debugInfo && <div className="leads2-mt2 leads2-text-blue-500">Debug info: {debugInfo}</div>}
        
        <div className="leads2-mt4 leads2-p3 leads2-bg-yellow-100 leads2-border leads2-border-yellow-400 leads2-rounded">
          <h3 className="leads2-font-semibold">Troubleshooting:</h3>
          <p>1. Check if the api_requests collection exists in your MongoDB</p>
          <p>2. Check if there are any records in the collection for this user</p>
          <p>3. Verify that customerId is stored in the same format in the collection (string vs ObjectId)</p>
          <p>4. Try manually inserting a test record into the api_requests collection with this format:</p>
          <pre className="leads2-bg-gray-100 leads2-p2 leads2-rounded leads2-mt2 leads2-text-xs">
{`{
  "customerId": "67cf591fcb9539a4cc828b9e",
  "timestamp": new Date(),
  "endpoint": "/api/test",
  "request_body": { "test": true },
  "status_code": 200,
  "response": {
    "timestamp": new Date(),
    "response_body": { "success": true }
  }
}`}
          </pre>
        </div>
      </div>
    );
  }
  
  if (!lastRequest) return <div className="leads2-p4">No API requests found for this user</div>;

  if (youtubers.length === 0) {
    return (
      <div className="leads2-p4">
        <h2 className="leads2-text-2xl leads2-font-bold leads2-mb4">API Request Found</h2>
        <div className="leads2-bg-white leads2-rounded-lg leads2-shadow leads2-p4">
          <p>The API request was found, but no youtuber data is available in the response.</p>
          <pre className="leads2-bg-gray-100 leads2-p2 leads2-rounded leads2-mt2 leads2-text-sm">
            {JSON.stringify(lastRequest, null, 2)}
          </pre>
        </div>
      </div>
    );
  }

  // Get the selected youtuber data
  const selectedYoutuberData = youtubers[selectedYoutuber];

  return (
    <div className="leads2-page">
      <Header activePage="leads2" />
      <div className="leads2-root-container">
        <div className="leads2-container">
          {/* Youtuber Sidebar */}
          <div className="leads2-sidebar">
            {youtubers.map((youtuber, index) => (
              <div
                key={index}
                className={`leads2-item ${selectedYoutuber === index ? 'leads2-active' : ''}`}
                onClick={() => handleSelectYoutuber(index)}
              >
                {youtuber.name || `Channel ${index+1}`}
              </div>
            ))}
          </div>

          {/* Main Content - Selected Youtuber Data */}
          {selectedYoutuberData && (
            <div className="leads2-content-wrapper">
              {/* Creator Info Section */}
              <div className="leads2-main-section">
                <h2 className="leads2-main-section-title" onClick={toggleCreatorInfoExpanded}>
                  <span className={`leads2-triangle ${creatorInfoExpanded ? 'leads2-expanded' : ''}`}>▶</span> Creator Info
                </h2>
                
                {creatorInfoExpanded && (
                  <div className="leads2-main-section-content">
                    {/* YoutuberProfile Section */}
                    <div className="leads2-subsection">
                      <h3 className="leads2-subsection-title" onClick={toggleProfileExpanded}>
                        <span className={`leads2-triangle ${profileExpanded ? 'leads2-expanded' : ''}`}>▶</span> YouTuber Profile
                      </h3>
                      {profileExpanded && (
                        <div className="leads2-subsection-content">
                          <p className="leads2-description">
                            {selectedYoutuberData.youtuberProfile}
                          </p>
                        </div>
                      )}
                    </div>

                    {/* Personas Section */}
                    <div className="leads2-subsection">
                      <h3 className="leads2-subsection-title" onClick={togglePersonasExpanded}>
                        <span className={`leads2-triangle ${personasExpanded ? 'leads2-expanded' : ''}`}>▶</span> Personas
                      </h3>
                      
                      {personasExpanded && (
                        <div className="leads2-subsection-content">
                          {/* Force re-render with key based on the selected youtuber */}
                          <div key={selectedYoutuber}>
                            {/* Show persona data, adjusting for the possible different formats */}
                            {console.log("Rendering personas for youtuber:", selectedYoutuber, selectedYoutuberData.personas)}
                            
                            {selectedYoutuberData.personas && selectedYoutuberData.personas.map((persona, index) => {
                              // Handle both {name, age, occupation, interests} and {persona, age, occupation, interests} formats
                              const personaName = persona.name || persona.persona || `Persona ${index + 1}`;
                              return (
                                <div key={`persona-${selectedYoutuber}-${index}`} className="leads2-persona-card">
                                  <h3 className="leads2-persona-name">{personaName}</h3>
                                  <div className="leads2-persona-details">
                                    <p><strong>Age:</strong> {persona.age}</p>
                                    <p><strong>Occupation:</strong> {persona.occupation}</p>
                                    <p><strong>Interests:</strong> {persona.interests}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Leads Section */}
              <div className="leads2-main-section">
                <h2 className="leads2-main-section-title" onClick={toggleLeadsExpanded}>
                  <span className={`leads2-triangle ${leadsExpanded ? 'leads2-expanded' : ''}`}>▶</span> Leads
                </h2>
                
                {leadsExpanded && (
                  <div className="leads2-main-section-content">
                    {selectedYoutuberData.brands && selectedYoutuberData.brands.map((brand, index) => (
                      <div key={index} className="leads2-brand-section">
                        <h3 className="leads2-brand-title" onClick={() => toggleBrandExpanded(brand.name)}>
                          <span className={`leads2-triangle ${expandedBrands[brand.name] ? 'leads2-expanded' : ''}`}>▶</span>
                          {brand.name} <span className="leads2-email-count"></span>
                        </h3>
                        
                        {expandedBrands[brand.name] && (
                          <div className="leads2-brand-content">
                            {/* Related Persona */}
                            <div className="leads2-brand-subsection">
                              <h4 className="leads2-brand-subsection-title">Related Persona</h4>
                              <div className="leads2-brand-subsection-content">
                                <p><strong>{brand.relatedPersona}</strong></p>
                              </div>
                            </div>
                            
                            {/* Company Description */}
                            <div className="leads2-brand-subsection">
                              <h4 className="leads2-brand-subsection-title">Company Description</h4>
                              <div className="leads2-brand-subsection-content">
                                <p>{brand.description}</p>
                              </div>
                            </div>
                            
                            {/* Focus Points */}
                            <div className="leads2-brand-subsection">
                              <h4 className="leads2-brand-subsection-title">Focus Points</h4>
                              <div className="leads2-brand-subsection-content">
                                {brand.focus && brand.focus.length > 0 ? (
                                  <ul className="leads2-focus-list">
                                    {brand.focus.map((focusItem, fIndex) => {
                                      // Display both focus/point and description if available
                                      if (typeof focusItem === 'object') {
                                        // Check for different possible object structures
                                        if (focusItem.point && focusItem.description) {
                                          return (
                                            <li key={fIndex} className="leads2-focus-item">
                                              {capitalizeFirstLetter(focusItem.point)}.
                                              <p>{focusItem.description}</p>
                                            </li>
                                          );
                                        } else if (focusItem.objective && focusItem.description) {
                                          return (
                                            <li key={fIndex} className="leads2-focus-item">
                                              {capitalizeFirstLetter(focusItem.objective)}
                                              <p>{focusItem.description}</p>
                                            </li>
                                          );
                                        } else if (focusItem.focus && focusItem.description) {
                                          return (
                                            <li key={fIndex} className="leads2-focus-item">
                                              <strong>{capitalizeFirstLetter(focusItem.focus)}</strong>
                                              <p>{focusItem.description}</p>
                                            </li>
                                          );
                                        } else if (focusItem.focus && typeof focusItem.focus === 'string') {
                                          return (
                                            <li key={fIndex} className="leads2-focus-item">
                                              <strong>{capitalizeFirstLetter(focusItem.focus)}</strong>
                                            </li>
                                          );
                                        } else {
                                          // For other object structures, stringify to prevent direct object rendering
                                          return (
                                            <li key={fIndex} className="leads2-focus-item">
                                              {JSON.stringify(focusItem)}
                                            </li>
                                          );
                                        }
                                      } else if (typeof focusItem === 'string') {
                                        // Handle case where focus items are just strings
                                        return (
                                          <li key={fIndex} className="leads2-focus-item">
                                            <strong>{capitalizeFirstLetter(focusItem)}</strong>
                                          </li>
                                        );
                                      } else {
                                        // Fallback for other types
                                        return (
                                          <li key={fIndex} className="leads2-focus-item">
                                            {String(focusItem)}
                                          </li>
                                        );
                                      }
                                    })}
                                  </ul>
                                ) : (
                                  <p>No focus points specified</p>
                                )}
                              </div>
                            </div>
                            
                            {/* Marketing Objective */}
                            <div className="leads2-brand-subsection">
                              <h4 className="leads2-brand-subsection-title">Marketing Objective</h4>
                              <div className="leads2-brand-subsection-content">
                                {typeof brand.marketingObjective === 'string' ? (
                                  <p>{brand.marketingObjective}</p>
                                ) : typeof brand.marketingObjective === 'object' ? (
                                  <div>
                                    {brand.marketingObjective.objective && (
                                      <>
                                        <strong>{brand.marketingObjective.objective}</strong>
                                        {brand.marketingObjective.description && (
                                          <p>{brand.marketingObjective.description}</p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <p>{String(brand.marketingObjective)}</p>
                                )}
                              </div>
                            </div>
                            
                            {/* Ready-To-Send Outreach */}
                            <div className="leads2-brand-subsection">
                              <h4 className="leads2-brand-subsection-title">Ready-To-Send Outreach</h4>
                              <div className="leads2-brand-subsection-content">
                                <div className="leads2-email-placeholder">
                                  <p className="leads2-email-subject">Subject: Partnership Opportunity with {brand.name}</p>
                                  <div className="leads2-email-body">
                                    {brand.outreachEmail.split('\n').map((line, i) => (
                                      <React.Fragment key={i}>
                                        {line}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Leads2;