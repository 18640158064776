import React, { useState, useEffect, useContext } from 'react';
import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import Header from './Header'; // Import the Header component
import './Settings.css'; // Import the new CSS file

function Settings() {
  const { user, setUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [agencyName, setAgencyName] = useState('');
  const [channels, setChannels] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  // 1) On mount (and whenever `user` changes), verify that we have a valid user,
  //    fetch the user details from the server, subscription info, etc.
// Replace your useEffect in Settings.js with this:

useEffect(() => {
  const checkUser = async () => {
    setLoading(true);
    try {
      let currentUser = user;

      if (!currentUser || !currentUser.token) {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          currentUser = JSON.parse(storedUser);
          setUser(currentUser);
        }
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`,
      };

      const userResponse = await fetch(`/api/users/${currentUser._id}`, { headers });
      if (!userResponse.ok) throw new Error(`Failed to fetch user (status: ${userResponse.status})`);

      const userData = await userResponse.json();
      setFirstName(userData.firstName || '');
      setAgencyName(userData.agencyName || '');

      const subResponse = await fetch(`/api/subscriptions/${currentUser._id}/active`, { headers });
      if (!subResponse.ok) throw new Error('Failed to fetch active subscription');

      const subData = await subResponse.json();
      setSubscription(subData);

    } catch (err) {
      // Handle error silently
    } finally {
      setLoading(false);
    }
  };

  checkUser();
}, [user, navigate, setUser]);



  

  // 2) Handler for updating personal details (Name, Agency)
  //    IMPORTANT: parse server response via `await response.json()`
  const handleDetailsUpdate = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
  
    if (!user || !user._id) {
      navigate('/auth');
      return;
    }
  
    try {
      const body = { firstName, agencyName: user.userType === 'agency' ? agencyName : null };
  
      const response = await fetch(`/api/users/${user._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`, // Ensure token is sent
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update user');
      }
  
      const data = await response.json();
  
      if (data.user) {
        const updatedUser = { ...data.user, token: user.token };
        setUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
      }
  
      setSuccessMessage(data.message || 'Details updated successfully!');
    } catch (err) {
      setSuccessMessage('Failed to update details. Please try again.');
    }
  };
  


  // 3) Handler for subscription changes
  const handleSubscriptionChange = () => navigate('/pricing');

  // 4) Handler to cancel the subscription
  const handleSubscriptionCancel = async () => {
    try {
      const response = await fetch(`/api/subscriptions/${user._id}/cancel`, {
        method: 'PUT',
      });
      if (!response.ok) throw new Error('Failed to cancel subscription');

      setSubscription((prev) => ({ ...prev, status: 'inactive', plan: 'No Plan' }));
      alert('Subscription canceled successfully!');
    } catch (err) {
      alert('Error canceling subscription. Please try again later.');
    }
  };
  const handleLogin = async (email, password) => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) throw new Error('Login failed');
  
      const data = await response.json();
  
      if (!data.token) {
        throw new Error("No token received in login response!");
      }
  
      setUser(data);
      localStorage.setItem('user', JSON.stringify(data));
  
      navigate('/settings');
    } catch (error) {
      // Handle error silently
    }
  };
  
  

  // Utility: convert plan → channel limit
  const getChannelLimit = (plan) => {
    switch (plan) {
      case 'agency-basic':
        return 10;
      case 'agency-plus':
        return 20;
      case 'agency-pro':
        return 30;
      default:
        return 0;
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="settings-outer-container">
      <Header />
      <div className="settings-container">
        <h2 className="settings-h2">Settings</h2>
        <div className="settings-content">
          <div className="settings-left">
            <div className="details-section">
              <h3>Personal Details</h3>
              <form onSubmit={handleDetailsUpdate} className="settings-form">
                <div className="input-section">
                  <h4>First Name</h4>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    className="settings-input"
                  />
                </div>
                {user?.userType === 'agency' && (
                  <div className="input-section">
                    <h4>Agency Name</h4>
                    <input
                      type="text"
                      value={agencyName}
                      onChange={(e) => setAgencyName(e.target.value)}
                      placeholder="Agency Name"
                      className="settings-input"
                    />
                  </div>
                )}
                <button type="submit" className="settings-button">
                  Save Details
                </button>
                {successMessage && <p className="success-message">{successMessage}</p>}
              </form>
            </div>

            <div className="subscription-section">
              <h3>Subscription</h3>
              {subscription && subscription.status ? (
                <>
                  <p>Plan: {subscription.status === 'inactive' ? 'No Plan' : subscription.plan}</p>
                  {subscription.status === 'active' ? (
                    <>
                      <button onClick={handleSubscriptionChange} className="settings-button">
                        Change Subscription
                      </button>
                      <button onClick={handleSubscriptionCancel} className="settings-button">
                        Cancel Subscription
                      </button>
                    </>
                  ) : (
                    <button onClick={() => navigate('/pricing')} className="settings-button">
                      Renew Subscription
                    </button>
                  )}
                </>
              ) : (
                <p>No active subscription</p>
              )}
            </div>
          </div>

          <div className="settings-right">
            {user?.userType === 'agency' && subscription?.status === 'active' && (
              <>
                <h3>Your Channels</h3>
                <button className="settings-button" onClick={() => navigate('/insert-channels')}>
                  Update Channel URLs
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;