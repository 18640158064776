import React from 'react';
import './FeaturesSection.css';

function FeaturesSection() {
  return (
    <div className="features-section-container">
      <h2 className="features-heading">Why Use Rubybeam?</h2>
      <div className="features-section">
        <div className="feature-box classification">
          <img src="/puzzle.png" alt="Save Time Icon" className="feature-icon" />
          <h4>Creator-Brand Matching</h4>
          <p>Sponsorship deals matched to your creators’ audience and content for the best partnerships.</p>
          <img src="/matching.png" alt="Time Illustration" className="time-illustration" />
        </div>
        <div className="feature-box regression">
          <img src="/business.png" alt="Direct Contacts Icon" className="feature-icon" />
          <h4>Direct Brand Contacts</h4>
          <p>Get direct contact details of brand decision-makers for sponsorship deals.</p>
          <img src="/email_contact.png" alt="Contact Illustration" className="email-illustration" />
        </div>
        <div className="feature-box time-series">
          <img src="/lead2.png" alt="Boost Sponsorship Icon" className="feature-icon" />
          <h4>Continuous Lead Discovery</h4>
          <p>Our AI scans the web for new sponsorships, delivering fresh, high-quality leads to you.</p>
          <img src="/magnet.png" alt="Boost Illustration" className="rocket-illustration" />
        </div>
      </div>
    </div>
  );
}

export default FeaturesSection;