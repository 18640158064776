import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import "./Billing.css";

function Billing() {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan } = location.state || {};
  const user = JSON.parse(localStorage.getItem("user"));

  const [popupMessage, setPopupMessage] = useState(null);

  useEffect(() => {
    document.body.classList.add("billing-page");
    return () => document.body.classList.remove("billing-page");
  }, []);

  if (!plan) {
    return <p>No plan selected. Please go back to the Pricing page.</p>;
  }

  const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID || "";
  if (!paypalClientId) {
    console.error("Missing REACT_APP_PAYPAL_CLIENT_ID. Ensure it's set in .env file.");
  }

  const initialOptions = {
    "client-id": paypalClientId,
    currency: "USD",
    intent: "subscription",
    vault: true,
    locale: "en_US",
  };

  const planIds = {
    Starter: process.env.REACT_APP_PAYPAL_AGENCY_STARTER,
    Growth: process.env.REACT_APP_PAYPAL_AGENCY_GROWTH,
    Pro: process.env.REACT_APP_PAYPAL_AGENCY_PRO,
  };

  const selectedPlanId = planIds[plan.name];
  if (!selectedPlanId) {
    console.error(`No PayPal Plan ID found for plan: ${plan.name}`);
  }

  // -----------------------------------------
  // Modified "onApprove" callback
  // -----------------------------------------
  const handleApprove = (data) => {
    console.log("[handleApprove] Called with data:", data);
  
    if (!user || !user.token) {
      console.error("User authentication token missing.");
      setPopupMessage("Authentication error. Please log in again.");
      return;
    }
  
    // 1) Create new "pending" subscription
    const subscriptionData = {
      customerId: user._id,
      plan: `${user.userType}-${plan.name.toLowerCase()}`,
      price: plan.price,
      emails: plan.emails,
      createdAt: new Date().toISOString(),
  
      // Pass PayPal's subscription ID so the server can match it later
      paypalSubscriptionId: data.subscriptionID,
    };
  
    if (user.userType === "agency") {
      subscriptionData.youtubers = plan.youtubers || 0;
    }
  
    console.log("[handleApprove] Sending POST /api/subscriptions with:", subscriptionData);
  
    fetch("/api/subscriptions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(subscriptionData),
    })
      .then((response) => {
        console.log("[handleApprove] /api/subscriptions response status:", response.status);
        if (!response.ok) {
          return response.json().then((err) => {
            throw new Error(err.message || "Failed to save subscription");
          });
        }
        return response.json();
      })
      .then((pendingSubJson) => {
        console.log("[handleApprove] Subscription created (pending):", pendingSubJson);
  
        // 2) Immediately confirm with PayPal's subscription API
        return fetch("/api/subscriptions/paypal-confirm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            subscriptionId: data.subscriptionID, // unique PayPal subscription ID
          }),
        });
      })
      .then((confirmResponse) => {
        console.log("[handleApprove] /api/subscriptions/paypal-confirm status:", confirmResponse.status);
        if (!confirmResponse.ok) {
          return confirmResponse.json().then((err) => {
            throw new Error(err.error || "Failed to confirm PayPal subscription");
          });
        }
        return confirmResponse.json();
      })
      .then((confirmJson) => {
        console.log("[handleApprove] Subscription confirmed as active:", confirmJson);
  
        // 3) Navigate after success
        if (user.userType === "agency") {
          console.log("[handleApprove] Navigating to /insert-channels for 'agency' user");
          navigate("/insert-channels", { state: { plan } });
        } else if (user.userType === "youtuber") {
          console.log("[handleApprove] Navigating to /generate for 'youtuber' user");
          navigate("/generate", { state: { plan } });
        } else {
          console.warn("[handleApprove] Unknown user type:", user.userType);
          setPopupMessage("Unknown user type. Please contact support.");
        }
      })
      .catch((err) => {
        console.error("Error saving or confirming subscription:", err);
        setPopupMessage("Error saving or confirming subscription. Please contact support.");
      });
  };
  

  return (
    <div className="billing-page-container">
      <Header activePage="emails" />
      <div className="cards-container">
        <div className="card">
          <h2>{plan.name} Plan</h2>
          <h4 className="email-amount">
            {plan.youtubers} YouTubers
          </h4>
          <h3 className="price">{`$${plan.price}/mo`}</h3>
          <div className="paypal-section">
            {paypalClientId && selectedPlanId ? (
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createSubscription={(data, actions) => {
                    console.log("[createSubscription] Creating with plan_id:", selectedPlanId);
                    return actions.subscription.create({
                      plan_id: selectedPlanId,
                      subscriber: {
                        name: {
                          given_name: user.firstName || "John",
                          surname: user.lastName || "Doe",
                        },
                        email_address: user.email || "test@example.com",
                        shipping_address: {
                          name: {
                            full_name: (user.firstName + " " + user.lastName) || "John Doe",
                          },
                          address: {
                            address_line_1: "123 Main St",
                            admin_area_2: "San Francisco",
                            admin_area_1: "CA",
                            postal_code: "94107",
                            country_code: "US",
                          },
                        },
                      },
                    });
                  }}
                  onApprove={(data, actions) => {
                    console.log("[onApprove] Called with data:", data);
                    handleApprove(data);
                  }}
                  onError={(err) => {
                    setPopupMessage("Subscription creation failed. Please try again.");
                    console.error("PayPal subscription error:", err);
                  }}
                />
              </PayPalScriptProvider>
            ) : (
              <p className="error-message">
                PayPal is not available. Please check your configuration.
              </p>
            )}
          </div>
          <p className="cancel-notice">
            You can cancel anytime in your profile settings.
          </p>
        </div>
        {popupMessage && <p className="popup-message">{popupMessage}</p>}
      </div>
    </div>
  );
}

export default Billing;